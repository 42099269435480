import React from 'react';

import {
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";

import './App.css';
import './spinner.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';

// import { formatDate } from './utils/parseDateTime'

import PrivateRoute from "./components/PrivateRoute.component"

import loadable from '@loadable/component'

import CheckoutPage from "./pages/Checkout/Checkout.page"
import SchoolManagementPage from './pages/MembersArea/SchoolManagement.page';
import InfoSchoolPage from './pages/MembersArea/InfoSchool.page';
import TermsAndPolicies from './pages/termsAndPolicies/ termsAndPolicies.page';

const HomePage = loadable(() => import( './pages/Home.page'));
const PasswordRecoveryPage = loadable(() => import( "./pages/PasswordRecovery/PasswordRecovery.page"));
const LoginPage = loadable(() => import( './pages/Login/Login.page'));
const PrivacyPoliciesPage = loadable(() => import('./pages/privacyPolicies/ PrivacyPolicies.page'))
const TermsOfUsePage = loadable(() => import('./pages/termsOfUse/ TermsOfUse.page'));
// const AdminPage = loadable(() => import( './pages/Admin/Admin.page'));
const RegisterPage = loadable(() => import( './pages/Register/Register.page'));
const AuthenticatedTemplate = loadable(() => import( './templates/Authenticated.template'));
const ProductsPage = loadable(() => import( "./pages/Product/Product.page"));
const AddProductPage = loadable(() => import( "./pages/Product/AddProduct.page"));
const MembersAreaPage = loadable(() => import( "./pages/MembersArea/MembersArea.page"));
const AddCoursePage = loadable(() => import( "./pages/MembersArea/AddCourse.page"));
const StudentsPage = loadable(() => import( './pages/MembersArea/Students.page'));
const NotFoundPage = loadable(() => import( "./pages/NotFound/NotFound.page"));
const IntegrationsPage = loadable(() => import( './pages/Integrations/Integrations.page'));
const WebhooksPage = loadable(() => import( "./pages/Integrations/subpages/Webhooks.page"));
const NotificationsPage = loadable(() => import( "./pages/Integrations/subpages/Notifications.page"));
const OrderPage = loadable(() => import( './pages/Order/Order.page'));
const SubscriptionPage = loadable(() => import( './pages/Subscription/Subscription.page'));
const FinancePàge = loadable(() => import( './pages/Finance/Finance.page'));
const ReportsPage = loadable(() => import( "./pages/Reports/Reports.page"));
const AdminCategoriesPage = loadable(() => import( "./pages/Admin/Categories.page"));
const MyAccountPage = loadable(() => import( "./pages/MyAccount/MyAccount.page"));
const MyOrdersPage = loadable(() => import( "./pages/MyOrders/MyOrders.page"));
const AffiliatePage = loadable(() => import( "./pages/Affiliates/Affiliates.page"));
const AffiliateMarketplacePage = loadable(() => import( "./pages/Affiliates/AffiliateMarketplace.page"));
const BatchPaymentPage = loadable(() => import( "./pages/BatchPayment/BatchPayment.page"));
const SupportPage = loadable(() => import( "./pages/Support/Support.page"));
const TicketsPage = loadable(() => import( "./pages/Support/Tickets.page"));
const HelpPage = loadable(() => import( "./pages/Support/HelpPage.page"));
const AbandonedCartPage = loadable(() => import( "./pages/AbandonedCart/AbandonedCart.page"));
const SchoolPage = loadable(() => import( './pages/MembersArea/School.page'));
const AddSchoolPage = loadable(() => import( './pages/MembersArea/AddSchool.page'));
const AddCoupomPage = loadable(() => import( './pages/Coupom/AddCoupom.page'));
const CoupomPage = loadable(() => import( './pages/Coupom/Coupom.page'));



export default function App() {
  return (
    <HashRouter basename="/">
      <div>
        <IntlProvider locale={ptBR}>
          <Switch>
            <Route exact={true} path="/checkout/:shortUrl">
              <CheckoutPage />
            </Route>
            <Route exact={true} path="/checkout/:shortUrl/ref/:affiliateCode">
              <CheckoutPage />
            </Route>
            <Route exact={true} path="/register">
              <RegisterPage />
            </Route>
            <Route exact={true} path="/404">
              <NotFoundPage />
            </Route>
            <Route exact={true} path="/login">
              <LoginPage />
            </Route>
            <Route exact={true} path="/privacy-policies">
              <PrivacyPoliciesPage/>
            </Route>
            <Route exacth={true} path="/terms-of-use">
              <TermsOfUsePage/>
            </Route>
            <Route exact={true} path="/terms-and-policies">
              <TermsAndPolicies/>
            </Route>
            <Route path="/forgot/:recoveryToken">
              <PasswordRecoveryPage />
            </Route>
            <Route exact={true} path="/forgot">
              <PasswordRecoveryPage />
            </Route>
            <PrivateRoute path="/integrations" exact={true} role="integrations.read">
              <AuthenticatedTemplate>
                <IntegrationsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/integrations/webhooks" exact={true} role="integrations.read">
              <AuthenticatedTemplate>
                <WebhooksPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/integrations/notifications" exact={true} role="integrations.read">
              <AuthenticatedTemplate>
                <NotificationsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            {/* <PrivateRoute path="/admin/:entity/edit/:id">
              <AuthenticatedTemplate>
                <AdminPage action="edit" />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            {/* <PrivateRoute path="/admin/:entity/create">
              <AuthenticatedTemplate>
                <AdminPage action="create"/>
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            {/* <PrivateRoute path="/admin/:entity">
              <AuthenticatedTemplate>
                <AdminPage action="list" />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            <PrivateRoute path="/product/create" role="product.create">
              <AuthenticatedTemplate>
                <AddProductPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/product/edit/:id" role="product.update">
              <AuthenticatedTemplate>
                <AddProductPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/product" role="product.read">
              <AuthenticatedTemplate>
                <ProductsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/coupom/create" role="coupom.create">
              <AuthenticatedTemplate>
                <AddCoupomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/coupom/edit/:id" role="coupom.update">
              <AuthenticatedTemplate>
                <AddCoupomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/coupom" role="coupom.read">
              <AuthenticatedTemplate>
                <CoupomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={false} path="/members" role="member_course.read">
              <AuthenticatedTemplate>
                <MembersAreaPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools" role="member_school.read">
              <AuthenticatedTemplate>
                <SchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools/add/" role="member_school.create">
              <AuthenticatedTemplate>
                <AddSchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools/edit/:id" role="member_school.update">
              <AuthenticatedTemplate>
                <AddSchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools/management" role="member_school.read">
              <AuthenticatedTemplate>
                <SchoolManagementPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools/info/:id" role="member_school.read">
              <AuthenticatedTemplate>
                <InfoSchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/students" role="member_student.read">
              <AuthenticatedTemplate>
                <StudentsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            {/* <PrivateRoute exact={true} path="/course/add">
              <AuthenticatedTemplate>
                <AddCoursePage />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            <PrivateRoute exact={true} path="/course/:id" role="member_course.create">
              <AuthenticatedTemplate>
                <AddCoursePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/orders" role="orders.read">
              <AuthenticatedTemplate>
                <OrderPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/subscriptions" role="subscriptions.read">
              <AuthenticatedTemplate>
                <SubscriptionPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/finance" role="finance.read">
              <AuthenticatedTemplate>
                <FinancePàge />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/reports" role="reports.read">
              <AuthenticatedTemplate>
                <ReportsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/dashboard">
              <AuthenticatedTemplate>
                <HomePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/account" role="my_account.read">
              <AuthenticatedTemplate>
                <MyAccountPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/myorders">
              <AuthenticatedTemplate>
                <MyOrdersPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/affiliates" role="affiliates.read">
              <AuthenticatedTemplate>
                <AffiliatePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/affiliate/invite/:id" role="affiliates.update">
              <AuthenticatedTemplate>
                <AffiliateMarketplacePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/batch-payment" role="batch_payment.read">
            <AuthenticatedTemplate>
              <BatchPaymentPage/>
            </AuthenticatedTemplate>
          </PrivateRoute>
            <PrivateRoute exact={true} path="/support">
              <AuthenticatedTemplate>
                <SupportPage/>
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/tickets">
            <AuthenticatedTemplate>
              <TicketsPage/>
            </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/support/order/:orderId">
              <AuthenticatedTemplate>
                <SupportPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/help/order/:orderId">
              <AuthenticatedTemplate>
                <HelpPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/support/subscription/:subscriptionId">
              <AuthenticatedTemplate>
                <SupportPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/help/subscription/:subscriptionId">
              <AuthenticatedTemplate>
                <HelpPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <Route path="/active/:activationToken">
              <LoginPage activation="true" />
            </Route>
            <PrivateRoute path="/" exact={true}>
              <AuthenticatedTemplate>
                <HomePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/abandoned/cart" role="abandoned_cart.read">
              <AuthenticatedTemplate>
                <AbandonedCartPage />
              </AuthenticatedTemplate>
            </PrivateRoute>

            {/* start Admin routes */}
              <PrivateRoute path="/admin/products/edit/:id" role="product.update">
                <AuthenticatedTemplate>
                  <AddProductPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/products" role="product.read">
                <AuthenticatedTemplate>
                  <ProductsPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/orders" role="orders.read">
                <AuthenticatedTemplate>
                  <OrderPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/subscriptions" role="subscriptions.read">
                <AuthenticatedTemplate>
                  <SubscriptionPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/categories" role="categories.read">
                <AuthenticatedTemplate>
                  <AdminCategoriesPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>              
            {/* end Admin routes */}

            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </IntlProvider>
      </div>
    </HashRouter>
  );
}
